import React from "react"

import * as styles from "./component.module.less"

const autoDismissTimeout = 10000; // milliseconds

class FlashMessages extends React.Component {

  timeoutId;
  autoDismissRunning = false;

  constructor(props) {
    super(props);

    this.onDismiss = this.onDismiss.bind(this);

    this.state = {
      waiting: [],
      showing: null
    };
  }

  add(text, type) {
    const msg = {
      text: text,
      type: type
    };

    if (this.state.showing === null) {
      this.setState(() => ({
        showing: msg
      }));
    } else {
      this.setState(() => ({
        waiting: (() => {
          const waiting = this.state.waiting;
          waiting.push(msg);
          return waiting;
        })()
      }));
    }

    this.autoDismiss();
  }

  onDismiss() {
    this.setState(() => ({
      showing: null
    }));

    clearTimeout(this.timeoutId);
    this.showNext()
  }

  autoDismiss() {
    if (this.autoDismissRunning) {
      return;
    }

    this.autoDismissRunning = true;

    this.timeoutId = setTimeout((() => {
      this.setState(() => ({
        showing: null
      }));

      this.showNext();
      /* eslint-disable-next-line no-extra-bind */
    }).bind(this), autoDismissTimeout);
  }

  showNext() {
    this.autoDismissRunning = false;

    if (this.state.waiting.length === 0) {
      return;
    }

    this.setState(() => ({
      waiting: this.state.waiting.slice(1),
      showing: this.state.waiting.shift()
    }));

    this.autoDismiss();
  }

  render() {
    if (this.state.showing === null) {
      return null;
    }

    let typeClass;
    switch(this.state.showing.type) {
      case 0:
        typeClass = styles.success;
        break;
      case 1:
        typeClass = styles.error;
        break;
      default:
        break;
    }

    return (
      <div className={styles.flashMessages}>
        <div className={`${styles.msg} ${typeClass}`}>
          {this.state.showing.text}
          <button className={`${styles.btn} btn clear`} onClick={this.onDismiss}>X</button>
        </div>
      </div>
    );
  }
}

export default FlashMessages;

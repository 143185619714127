import {Helmet} from "react-helmet";
import React from "react";

import RemoteAssetUrl from "../../lib/remote-asset-url"

export default (props) => {

  const page = props.page || {
    seo: {}
  };

  if (!page.seo) {
    page['seo'] = {};
  }

  if (!page.seo.type) {
    let type = props.page ? (props.page.seo.type ? props.page.seo.type : null) : null;
    if (type === null) {
      type = 'website';
    }
    page.seo.type = type;
  }

  return (
      <Helmet htmlAttributes={{ lang : 'de' }} defer={false}>
        {page.seo.title &&
          <title>{page.seo.title}</title>
        }
        <meta name="apple-itunes-app" content="app-id=1064964745" />
        <meta name="theme-color" content="#1069AB" />
        <meta name="msapplication-square310x310logo" content="/assets/app/310.png" />
        <meta name="format-detection" content="telephone=no" />
        {page.seo.description &&
          <meta name="description" content={page.seo.description} />
        }
        {page.seo.robots &&
          <meta name="robots" content={page.seo.robots} />
        }

        {/* OpenGraph */}
        {page.seo.title &&
          <meta property="og:title" content={page.seo.title} />
        }
        {page.seo.description &&
          <meta name="og:description" content={page.seo.description} />
        }
        {page.seo.image && page.seo.image.fluid &&
          <meta name="og:image" content={RemoteAssetUrl(page.seo.image.fluid.src)} />
        }
        <meta property="og:type" content={page.seo.type} />
        <meta property="og:locale" content="de_DE" />

        {/* Twitter Card */}
        {page.seo.twitterCard &&
          <meta property="twitter:card" content={page.seo.twitterCard} />
        }
        {page.seo.title &&
          <meta property="twitter:title" content={page.seo.title} />
        }
        {page.seo.description &&
          <meta name="twitter:description" content={page.seo.description} />
        }
        {page.seo.image && page.seo.image.fluid &&
          <meta name="twitter:image" content={RemoteAssetUrl(page.seo.image.fluid.src)} />
        }

        {/* LINKS */}
        <link rel="icon" sizes="192x192" href="/assets/app/192.png" />
        <link rel="apple-touch-icon" href="/assets/app/180.png" />
        {page.seo.canonical &&
          <link rel="canonical" href={page.seo.canonical} />
        }

        {props.children}
      </Helmet>
  );
}

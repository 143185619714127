import React from "react"

import * as styles from "./component.module.less"

export default props => {
  const elProps = {
    id: props.anchor,
    className: `${styles.container} ${props.className || ""} ${
      props.width && props.width === "50%" ? styles.slim : ""
    }`,
  }

  switch (props.tag) {
    case "header":
      return <header {...elProps}>{props.children}</header>
    case "footer":
      return <footer {...elProps}>{props.children}</footer>
    case "section":
      return <section {...elProps}>{props.children}</section>
    default:
      return <div {...elProps}>{props.children}</div>
  }
}

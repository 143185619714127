export default (src) => {
  if (!src) {
    return '';
  }

  if (src.length === 0) {
    return '';
  }

  return src.replace(/https:\/\/www\.datocms-assets\.com\//, 'https://media.bkk-linde.de/assets/');
}

import React from "react"
import FlashMessagesRenderer from "./flash-messages"

export const GlobalContext = new React.createContext(42);

export default class GlobalProvider extends React.Component {

    flashMessagesRef;

    constructor(props) {
        super(props);

        this.flashMessagesRef = React.createRef();

        this.state = {
            flashMessages: {
                addSuccess: this.addSuccessFlashMessage.bind(this),
                addError: this.addErrorFlashMessage.bind(this),
            }
        };
    }

    addSuccessFlashMessage(text) {
        if (!this.flashMessagesRef.current) {
            return;
        }

        this.flashMessagesRef.current.add(text, 0);
    }

    addErrorFlashMessage(text) {
        if (!this.flashMessagesRef.current) {
            return;
        }

        this.flashMessagesRef.current.add(text, 1);
    }

    render() {
        return (
            <GlobalContext.Provider value={this.state}>
                <FlashMessagesRenderer ref={this.flashMessagesRef} />
                {this.props.children}
            </GlobalContext.Provider>
        );
    }
}
